// Bing API  督导 ->成本录入
import request from "@/utils/request";
export function querystatisticalListPage(data) {
  return request({
    url: "/statistics/list",
    method: "post",
    data,
  });
}
export function add(data) {
  return request({
    url: "/statistics/save",
    method: "post",
    data,
  });
}
export function update(data) {
  return request({
    url: "/statistics/update",
    method: "post",
    data,
  });
}
export function getInfo(data) {
  return request({
    url: "/statistics/detail",
    method: "post",
    data,
  });
}
export function delInfo(data) {
  return request({
    url: "/statistics/delete",
    method: "post",
    data,
  });
}
export function checkWorkOrder(data) {
  return request({
    url: "/statistics/checkWorkOrderNo",
    method: "post",
    data,
  });
}
