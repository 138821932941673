<!--Kyle 质控工作台 ->  工单列表-->
<template>
  <el-card class="box-card">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button icon="el-icon-search"
                     plain
                     native-type="submit"
                     @click="handleQuery">
            查询
          </el-button>
          <el-button icon="el-icon-refresh-left"
                     type="info"
                     plain
                     native-type="submit"
                     @click="resetForm('queryForm')">
            重置
          </el-button>
          <el-button icon="el-icon-sort"
                     plain
                     native-type="submit"
                     @click="heightQuery">
            高级筛选
          </el-button>

          <el-form ref="queryForm"
                   :model="queryForm"
                   style="margin-top: 15px"
                   class="demo-form-inline my-form-class">
            <el-row :gutter="20">
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 下单日期范围  -->
                <el-form-item prop="orderTime">
                  <el-date-picker v-model="queryForm.orderTime"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="下单开始日期"
                                  end-placeholder="下单结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 调查员委托日期范围  -->
                <el-form-item prop="surveyEntrustDate">
                  <el-date-picker v-model="queryForm.surveyEntrustDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="调查员委托开始日期"
                                  end-placeholder="调查员委托结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 调查员走访日期范围  -->
                <el-form-item prop="surveyVisitDate">
                  <el-date-picker v-model="queryForm.surveyVisitDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="调查员走访开始日期"
                                  end-placeholder="调查员走访结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24"
                      :sm="24"
                      :md="12"
                      :lg="6"
                      :xl="6">
                <!-- 调查员提交日期范围  -->
                <el-form-item prop="surveyCommitDate">
                  <el-date-picker v-model="queryForm.surveyCommitDate"
                                  type="daterange"
                                  unlink-panels
                                  range-separator="至"
                                  start-placeholder="调查员提交开始日期"
                                  end-placeholder="调查员提交结束日期"
                                  :picker-options="pickerOptions"></el-date-picker>
                </el-form-item>
              </el-col>
            </el-row>
            <transition name="fade">
              <el-row v-show="isShow"
                      transiton="fade"
                      :gutter="20">
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <!-- 内审提交日期范围  -->
                  <el-form-item prop="auditCommitDate">
                    <el-date-picker v-model="queryForm.auditCommitDate"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="内审提交开始日期"
                                    end-placeholder="内审提交结束日期"
                                    :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>
                <el-col :xs="24"
                        :sm="24"
                        :md="12"
                        :lg="6"
                        :xl="6">
                  <!-- 是否完成  -->
                  <el-form-item prop="accomplishFlag">
                    <el-select v-model="queryForm.accomplishFlag"
                               placeholder="请选择是否完成"
                               clearable
                               filterable>
                      <el-option v-for="item in finishOptions"
                                 :key="item.value"
                                 :label="item.label"
                                 :value="item.value"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>
            </transition>
          </el-form>
        </el-card>
      </el-col>
    </el-row>

    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-button type="primary"
                     @click="handleDownload">导出</el-button>
          <el-button type="primary"
                     @click="handleShowDownloadDialog">
            全量导出
          </el-button>
          <el-popover placement="right"
                      width="700"
                      trigger="click"
                      title="分析统计数据全量导出"
                      style="text-align: center"
                      @show="handleShowTaskPopover"
                      @hide="handleHideTaskPopover">
            <el-table v-loading="downloadTaskLoading"
                      :data="downloadList"
                      :show-header="false"
                      empty-text="暂无下载任务">
              <el-table-column :show-overflow-tooltip="true"
                               min-width="250"
                               prop="fileName" />
              <el-table-column min-width="150"
                               prop="createTime" />
              <el-table-column :show-overflow-tooltip="true"
                               min-width="110"
                               align="center"
                               prop="taskStatusValue" />
              <el-table-column width="90"
                               align="center">
                <template v-slot="slot">
                  <el-button v-if="slot.row.type === 3"
                             :disabled="slot.row.taskStatus !== 102"
                             type="text"
                             @click="handleDownloadZip(ossDomain, slot.row.downloadPath)">
                    下载
                  </el-button>
                  <el-button v-if="slot.row.taskStatus === 103"
                             type="text"
                             @click="handleRetry(slot.row.seqId)">
                    重试
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination small
                           layout="prev, pager, next"
                           :current-page="queryDownloadTaskForm.body.pageNum"
                           :page-size="queryDownloadTaskForm.body.pageSize"
                           :total="downloadTaskTotal"
                           @current-change="handleTaskCurrentChange"></el-pagination>
            <el-button slot="reference"
                       class="task-list-btn"
                       icon="el-icon-download"
                       title="查看全量导出下载任务状态"
                       type="primary"></el-button>
          </el-popover>

          <el-table ref="tableSort"
                    v-loading="listLoading"
                    :data="list"
                    :element-loading-text="elementLoadingText"
                    style="margin-top: 15px"
                    border
                    @selection-change="setSelectRows">
            <el-table-column type="selection"
                             width="40"></el-table-column>
            <el-table-column label="工单编号"
                             width="130"
                             prop="workOrderNo"
                             align="center"
                             fixed></el-table-column>
            <el-table-column prop="targetSubject"
                             label="目标主体"
                             width="150"></el-table-column>
            <el-table-column label="省"
                             prop="state">
              <template slot-scope="scope">
                {{ scope.row.state || "-" }}
              </template>
            </el-table-column>

            <el-table-column label="市"
                             prop="city">
              <template slot-scope="scope">
                {{ scope.row.city || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="区/县/地级市"
                             prop="region">
              <template slot-scope="scope">
                {{ scope.row.region || "-" }}
              </template>
            </el-table-column>

            <el-table-column label="下单日期_日"
                             prop="orderDate">
              <template slot-scope="scope">
                {{ scope.row.orderDate || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="调查员委托日期"
                             prop="surveyVisitDate">
              <template slot-scope="scope">
                {{ scope.row.surveyVisitDate || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="调查员走访日期"
                             prop="surveyEntrustDate">
              <template slot-scope="scope">
                {{ scope.row.surveyEntrustDate || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="调查员提交日期"
                             prop="surveyCommitDate">
              <template slot-scope="scope">
                {{ scope.row.surveyCommitDate || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="内审提交日期"
                             prop="auditCommitDate">
              <template slot-scope="scope">
                {{ scope.row.auditCommitDate || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="Project Name"
                             prop="projectNameNo">
              <template slot-scope="scope">
                {{ scope.row.projectNameNo || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="工单是否完成"
                             prop="accomplishFlagValue">
              <template slot-scope="scope">
                {{ scope.row.accomplishFlagValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="PL"
                             prop="plUserValue">
              <template slot-scope="scope">
                {{ scope.row.plUserValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="预约"
                             prop="bookUserValue">
              <template slot-scope="scope">
                {{ scope.row.bookUserValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="督导"
                             prop="monitorUserValue">
              <template slot-scope="scope">
                {{ scope.row.monitorUserValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="调查"
                             prop="surveyUserValue">
              <template slot-scope="scope">
                {{ scope.row.surveyUserValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="调查员类型"
                             prop="surveyUserType">
              <template slot-scope="scope">
                {{ scope.row.surveyUserType || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="内审"
                             prop="auditUserValue">
              <template slot-scope="scope">
                {{ scope.row.auditUserValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="用户"
                             prop="pchUserValue">
              <template slot-scope="scope">
                {{ scope.row.pchUserValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="放弃类型"
                             prop="abandonTypeValue">
              <template slot-scope="scope">
                {{ scope.row.abandonTypeValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="HK退回"
                             prop="hkFallbackValue">
              <template slot-scope="scope">
                {{ scope.row.hkFallbackValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="工单状态"
                             prop="workOrderStatusValue">
              <template slot-scope="scope">
                {{ scope.row.workOrderStatusValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="环节/整体滞留时间"
                             prop="stepAndStranded">
              <template slot-scope="scope">
                {{ scope.row.stepAndStranded || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目来源"
                             prop="sourceValue">
              <template slot-scope="scope">
                {{ scope.row.sourceValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目名称"
                             prop="projectName">
              <template slot-scope="scope">
                {{ scope.row.projectName || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="项目类型"
                             prop="projectType">
              <template slot-scope="scope">
                {{ scope.row.projectType || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="到期时间"
                             prop="maturityDate">
              <template slot-scope="scope">
                {{ scope.row.maturityDate || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="委托方"
                             prop="clientFullName">
              <template slot-scope="scope">
                {{ scope.row.clientFullName || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="对账ID"
                             prop="reconciliationId">
              <template slot-scope="scope">
                {{ scope.row.reconciliationId || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="SV Code"
                             prop="svCode">
              <template slot-scope="scope">
                {{ scope.row.svCode || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="报告期限"
                             prop="reportTimeLimitValue">
              <template slot-scope="scope">
                {{ scope.row.reportTimeLimitValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="走访收费"
                             prop="visitFee">
              <template slot-scope="scope">
                {{ scope.row.visitFee || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="加急走访收费"
                             prop="urgentVisitFee">
              <template slot-scope="scope">
                {{ scope.row.urgentVisitFee || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="再次走访收费"
                             prop="againVisitFee">
              <template slot-scope="scope">
                {{ scope.row.againVisitFee || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="是否扣罚"
                             prop="deductionFlagValue">
              <template slot-scope="scope">
                {{ scope.row.deductionFlagValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="是否质疑"
                             prop="questionFlagValue">
              <template slot-scope="scope">
                {{ scope.row.questionFlagValue || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="督导总绩效(元)"
                             prop="supervision">
              <template slot-scope="scope">
                {{ scope.row.supervision || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="调查总绩效(元)"
                             prop="investigation">
              <template slot-scope="scope">
                {{ scope.row.investigation || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="内审总绩效(元)"
                             prop="internalAudit">
              <template slot-scope="scope">
                {{ scope.row.internalAudit || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="成本总金额(元)"
                             prop="costAmount">
              <template slot-scope="scope">
                {{ scope.row.costAmount || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="报销总金额(元)"
                             prop="amount">
              <template slot-scope="scope">
                {{ scope.row.amount || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="快递公司"
                             prop="courierCompany">
              <template slot-scope="scope">
                {{ scope.row.courierCompany || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="快递单号"
                             prop="courierNumber">
              <template slot-scope="scope">
                {{ scope.row.courierNumber || "-" }}
              </template>
            </el-table-column>

            <el-table-column label="操作"
                             width="100px"
                             fixed="right"
                             align="center">
              <template slot-scope="scope">
                <el-tooltip class="item"
                            effect="dark"
                            content="详情"
                            placement="top-start">
                  <el-button size="mini"
                             type="primary"
                             icon="el-icon-document"
                             circle
                             @click="goDetailPage(scope.row)"></el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>
    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24"
              :sm="24"
              :md="12"
              :lg="24"
              :xl="24">
        <el-card>
          <el-pagination :background="background"
                         :current-page="queryForm.pageNum"
                         :layout="layout"
                         :page-size="queryForm.pageSize"
                         :total="total"
                         @current-change="handleCurrentChange"
                         @size-change="handleSizeChange"></el-pagination>
        </el-card>
      </el-col>
    </el-row>
    <!-- 下载确认对话框 -->
    <el-dialog title="下载确认"
               :visible.sync="downloadVisible"
               width="30%"
               append-to-body
               class="downloadDialog">
      <span>是否要下载所有分析统计的数据？</span>
      <span slot="footer"
            class="dialog-footer">
        <el-button @click="downloadVisible = false">取 消</el-button>
        <el-button :loading="downloadLoading"
                   type="primary"
                   @click="downloadAll">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </el-card>
</template>

<script>
import { getDataList, allExportExcel } from "@/api/data"
import {
	returnBitTimestamp,
	returnDeadtimestamp,
	getRoleKey,
	isExistOSS,
	downloadReport,
} from "@/utils"
import {
	createDownloadTask,
	fetchDownloadTaskList,
	rebuildDownloadTask,
} from "@/api/workplace/workDetail"
import { mapGetters } from "vuex"

export default {
	name: "StatisticalAnalysis",
	data() {
		return {
			isShow: false,
			loading: false,
			title: [],
			list: [],
			imageList: [],
			listLoading: false,
			layout: "total, sizes, prev, pager, next, jumper",
			total: 0,
			background: true,
			selectRows: "",
			elementLoadingText: "正在加载...",
			pickerOptions: {
				shortcuts: [
					{
						text: "最近一周",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近一个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
							picker.$emit("pick", [start, end])
						},
					},
					{
						text: "最近三个月",
						onClick(picker) {
							const end = new Date()
							const start = new Date()
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
							picker.$emit("pick", [start, end])
						},
					},
				],
			},
			queryForm: {
				orderTime: [], // 下单日期
				orderTimeStart: null,
				orderTimeEnd: null,

				surveyEntrustDate: [], // 调查员委托日期
				surveyEntrustDateStart: null,
				surveyEntrustDateEnd: null,

				surveyVisitDate: [], // 调查员走访日期
				surveyVisitDateStart: null,
				surveyVisitDateEnd: null,

				surveyCommitDate: [], // 调查员提交日期
				surveyCommitDateStart: null,
				surveyCommitDateEnd: null,

				auditCommitDate: [], // 内审提交日期
				auditCommitDateStart: null,
				auditCommitDateEnd: null,

				accomplishFlag: null, // 是否完成 0_未完成,1_已完成

				pageNum: 1,
				pageSize: 10,
			},
			// 质控工作台查询工单列表参数
			param: {
				body: {},
				header: {
					currentTime: "2020-08-04 00:00:00",
					requestId: "21122",
					sourceType: "site",
				},
			},

			finishOptions: [
				{
					value: "1",
					label: "是",
				},
				{
					value: "0",
					label: "否",
				},
			],
			recordList: null,
			flag: false,
			downloadVisible: false,
			// 创建下载任务loading
			downloadLoading: false,
			// 下载任务列表定时器
			downloadTimer: null,
			// 任务列表loading
			downloadTaskLoading: false,
			// 下载任务列表
			downloadList: [],
			// 下载任务总数
			downloadTaskTotal: 0,
			// 下载任务查询参数
			queryDownloadTaskForm: {
				body: {
					pageNum: 1,
					pageSize: 5,
					userNo: "",
					userRoleNo: "",
					workOrderNo: "",
				},
				header: {
					currentTime: "",
					requestId: "111",
					sourceType: "site",
				},
			},
			// 下载任务重试参数
			retryDownloadTaskForm: {
				body: {
					seqId: "",
					userNo: "",
					userRoleNo: "",
				},
				header: {
					currentTime: "",
					requestId: "111",
					sourceType: "site",
				},
			},
		}
	},
	computed: {
		...mapGetters({
			userNo: "user/userNo",
			roles: "user/roles",
			ossDomain: "user/endpoinxBak",
			OSSClient: "user/OSSClient",
		}),
	},

	created() {
		this.param.body.userNo = this.userNo
		this.param.body.userRoleNo = getRoleKey("manager", this.roles)
		this.fetchData()
	},
	beforeDestroy() {
		window.clearInterval(this.downloadTimer)
		this.downloadTimer = null
	},
	methods: {
		// 导出前端实现
		handleDownload() {
			if (this.selectRows.length > 0) {
				let tip = "导出已完成"
				this.download(this.selectRows, tip)
			} else {
				this.$baseMessage("未选中任何行", "error")
				return false
			}
		},

		download(param, tip) {
			import("@/vendor/ExportExcel").then((excel) => {
				const tHeader = [
					"工单编号",
					"订单编号",
					"目标主体",
					"省",
					"市",
					"区/县/地级市",
					"下单日期_日",
					"调查员委托日期",
					"调查员走访日期",
					"调查员提交日期",
					"内审提交日期",
					"Project Name",
					"工单是否完成",
					"PL",
					"预约",
					"督导",
					"调查",
					"调查员类型",
					"内审",
					"用户",
					"放弃类型",
					"HK退回",
					"工单状态",
					"环节/整体滞留时间",
					"项目来源",
					"项目名称",
					"项目类型",
					"报告类型",
					"二级类型",
					"到期时间",
					"委托方",
					"对账ID",
					"SV Code",
					"报告期限",
					"走访收费",
					"加急走访收费",
					"再次走访收费",
					"是否扣罚",
					"是否质疑",
					"督导总绩效(元)",
					"调查总绩效(元)",
					"内审总绩效(元)",
					"成本总金额(元)",
					"报销总金额(元)",
					"快递公司",
					"快递单号",
				]
				const filterVal = [
					"workOrderNo",
					"brWorkNo",
					"targetSubject",
					"state",
					"city",
					"region",
					"orderDate",
					"surveyVisitDate",
					"surveyEntrustDate",
					"surveyCommitDate",
					"auditCommitDate",
					"projectNameNo",
					"accomplishFlagValue",
					"plUserValue",
					"bookUserValue",
					"monitorUserValue",
					"surveyUserValue",
					"surveyUserType",
					"auditUserValue",
					"pchUserValue",
					"abandonTypeValue",
					"hkFallbackValue",
					"workOrderStatusValue",
					"stepAndStranded",
					"sourceValue",
					"projectName",
					"projectType",
					"reportType",
					"subReportType",
					"maturityDate",
					"clientFullName",
					"reconciliationId",
					"svCode",
					"reportTimeLimitValue",
					"visitFee",
					"urgentVisitFee",
					"againVisitFee",
					"deductionFlagValue",
					"questionFlagValue",
					"supervision",
					"investigation",
					"internalAudit",
					"costAmount",
					"amount",
					"courierCompany",
					"courierNumber",
				]
				const list = param
				const data = this.formatJson(filterVal, list)

				excel.export_json_to_excel({
					header: tHeader,
					data,
					filename: "工单分析统计列表",
					autoWidth: true,
					bookType: "xlsx",
					sheetName: "sheet",
				})
				this.$baseAlert(tip, "", () => {
					this.loading = false
				})
			})
		},
		// 组装导出数据
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) =>
				filterVal.map((j) => {
					if (!v[j]) {
						return "——"
					}
					return v[j]
				})
			)
		},

		// 质控工作台查询工单列表
		async fetchData() {
			const { body } = await getDataList(this.buildParam())
			this.list = body.records
			this.total = body.total
			this.$emit("onChange", this.total)
			setTimeout(() => {
				this.listLoading = false
			}, 500)
		},

		buildParam() {
			this.listLoading = true
			// 下单日期范围
			if (null !== this.queryForm.orderTime) {
				if (0 < this.queryForm.orderTime.length) {
					this.queryForm.orderTimeStart = returnBitTimestamp(
						this.queryForm.orderTime[0]
					)
					this.queryForm.orderTimeEnd = returnDeadtimestamp(
						this.queryForm.orderTime[1]
					)
				}
			} else {
				this.queryForm.orderTimeStart = null
				this.queryForm.orderTimeEnd = null
			}
			// 调查员委托日期
			if (null !== this.queryForm.surveyEntrustDate) {
				if (0 < this.queryForm.surveyEntrustDate.length) {
					this.queryForm.surveyEntrustDateStart = returnBitTimestamp(
						this.queryForm.surveyEntrustDate[0]
					)
					this.queryForm.surveyEntrustDateEnd = returnDeadtimestamp(
						this.queryForm.surveyEntrustDate[1]
					)
				}
			} else {
				this.queryForm.surveyEntrustDateStart = null
				this.queryForm.surveyEntrustDateEnd = null
			}
			// 调查员走访日期
			if (null !== this.queryForm.surveyVisitDate) {
				if (0 < this.queryForm.surveyVisitDate.length) {
					this.queryForm.surveyVisitDateStart = returnBitTimestamp(
						this.queryForm.surveyVisitDate[0]
					)
					this.queryForm.surveyVisitDateEnd = returnDeadtimestamp(
						this.queryForm.surveyVisitDate[1]
					)
				}
			} else {
				this.queryForm.surveyVisitDateStart = null
				this.queryForm.surveyVisitDateEnd = null
			}
			// 调查员提交日期
			if (null !== this.queryForm.surveyCommitDate) {
				if (0 < this.queryForm.surveyCommitDate.length) {
					this.queryForm.surveyCommitDateStart = returnBitTimestamp(
						this.queryForm.surveyCommitDate[0]
					)
					this.queryForm.surveyCommitDateEnd = returnDeadtimestamp(
						this.queryForm.surveyCommitDate[1]
					)
				}
			} else {
				this.queryForm.surveyCommitDateStart = null
				this.queryForm.surveyCommitDateEnd = null
			}
			// 内审提交日期
			if (null !== this.queryForm.auditCommitDate) {
				if (0 < this.queryForm.auditCommitDate.length) {
					this.queryForm.auditCommitDateStart = returnBitTimestamp(
						this.queryForm.auditCommitDate[0]
					)
					this.queryForm.auditCommitDateEnd = returnDeadtimestamp(
						this.queryForm.auditCommitDate[1]
					)
				}
			} else {
				this.queryForm.auditCommitDateStart = null
				this.queryForm.auditCommitDateEnd = null
			}
			this.param.body = Object.assign(this.param.body, this.queryForm)
			return this.param
		},

		setSelectRows(val) {
			this.selectRows = val
		},
		// 重置
		resetForm(formName) {
			if (this.$refs[formName] !== undefined) {
				this.$refs[formName].resetFields()
				this.queryForm = this.$options.data().queryForm
			}
		},
		handleSizeChange(val) {
			this.queryForm.pageSize = val
			this.fetchData()
		},
		handleCurrentChange(val) {
			this.queryForm.pageNum = val
			this.fetchData()
		},
		heightQuery() {
			this.isShow = !this.isShow
		},
		handleQuery() {
			this.queryForm.pageNum = 1
			this.fetchData()
			this.recordList = null
		},
		// 打开工单单详情页面
		goDetailPage(row) {
			let routeData = this.$router.resolve({
				path: "/data/statisticalDetail",
				name: "Detail",
				query: {
					target: row.targetSubject,
					workOrderNo: row.workOrderNo,
					address: row.address,
					projectName: row.projectName,
					projectId: row.projectId,
					seqId: row.seqId,
					userNo: this.userNo,
					plUser: row.plUser,
					userRoleNo: getRoleKey("supervisor", this.roles),
				},
			})
			/*** 截掉site字符 */
			routeData.href = routeData.href.slice(5)
			window.open(routeData.href, "_blank")
		},
		/** 显示下载任务弹出框 */
		handleShowTaskPopover() {
			this.listLoading = false
			this.fetchDownloadTaskData()
			window.clearInterval(this.downloadTimer)
			this.downloadTimer = window.setInterval(
				this.fetchDownloadTaskData,
				10 * 1000
			)
		},
		/** 隐藏下载任务弹出框 */
		handleHideTaskPopover() {
			this.listLoading = false
			window.clearInterval(this.downloadTimer)
		},
		/** 下载zip包 */
		handleDownloadZip(domain, path) {
			const downloadPath = domain + path
			isExistOSS(this.OSSClient, path)
				.then(() => {
					downloadReport(downloadPath)
				})
				.catch(() => {
					this.$message.error("当前文件不存在！")
				})
		},
		/** 重新创建下载任务 */
		async handleRetry(data) {
			this.param.body.type = 3
			this.downloadLoading = true
			this.param.body.mediaNumber = this.total
			const { header } = await rebuildDownloadTask(this.buildParam())
			if (header.retCode === "1") {
				this.$message.success(header.retMessage)
				this.fetchDownloadTaskData()
			} else {
				this.$message.error(header.retMessage)
			}
		},
		/** 查询下载任务列表 */
		async fetchDownloadTaskData() {
			this.downloadTaskLoading = true
			this.queryDownloadTaskForm.body.type = 3
			this.queryDownloadTaskForm.body.userNo = this.userNo
			this.queryDownloadTaskForm.body.userRoleNo = getRoleKey(
				"manager",
				this.roles
			)

			try {
				const { header, body } = await fetchDownloadTaskList(
					this.queryDownloadTaskForm
				)
				if (header.retCode === "1") {
					this.downloadTaskTotal = body.total
					this.downloadList = body.records
					this.listLoading = false
				} else {
					this.$message.error(heder.retMessage)
				}
				this.downloadTaskLoading = false
			} catch (error) {
				this.downloadList = []
				this.downloadTaskLoading = false
			}
		},
		/** 下载任务列表页码改变 */
		handleTaskCurrentChange(val) {
			this.queryDownloadTaskForm.body.pageNum = val
			this.fetchDownloadTaskData()
		},
		/** 创建下载任务 */
		async downloadAll() {
			this.param.body.type = 3
			this.downloadLoading = true
			this.param.body.mediaNumber = this.total
			try {
				const { header } = await createDownloadTask(this.buildParam())
				if (header.retCode === "1") {
					this.$message.success(header.retMessage)
					await this.fetchDownloadTaskData()
				} else {
					this.$message.error(header.retMessage)
				}
				this.downloadLoading = false
			} catch (error) {
				this.downloadLoading = false
			}
			this.downloadVisible = false
		},
		/** 展示下载提示遮罩 */
		handleShowDownloadDialog() {
			this.downloadVisible = true
		},
	},
}
</script>
<style scoped>
.el-col {
	margin-bottom: 10px;
}
.el-col :last-child {
	margin-bottom: 0;
}

.el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
	width: 100%;
}

.el-select,
.el-cascader {
	width: 100%;
}
.el-table /deep/.warning-row {
	background: #f8b7bc;
}
.my-form-class {
	margin-bottom: -10px !important;
}
.task-list-btn {
	margin-left: 10px;
}
</style>
