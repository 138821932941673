import request from "@/utils/request";

export function getDataList(data) {
  return request({
    url: "/queryAnalyticStatisticsList",
    method: "post",
    data,
  });
}
export function allExportExcel(data) {
  return request({
    url: "/allExportExcel",
    method: "post",
    data,
  });
}
