<template>
  <div class="table-container">
    <!-- 导航查询模板 -->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-button
            icon="el-icon-search"
            plain
            native-type="submit"
            @click="handleQuery"
          >
            查询
          </el-button>
          <el-button
            icon="el-icon-refresh-left"
            type="info"
            plain
            native-type="submit"
            @click="resetForm('queryForm')"
          >
            重置
          </el-button>

          <el-form
            ref="queryForm"
            :model="queryForm"
            style="margin-top: 15px"
            class="demo-form-inline my-form-class"
            @submit.native.prevent
          >
            <el-row :gutter="20">
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <!-- 下单日期范围  -->
                <el-form-item prop="date">
                  <el-date-picker
                    v-model="queryForm.date"
                    type="daterange"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="日志开始日期"
                    end-placeholder="日志结束日期"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :xs="24" :sm="24" :md="12" :lg="6" :xl="6">
                <el-form-item prop="operContent">
                  <el-input
                    v-model.trim="queryForm.operContent"
                    placeholder="内容信息"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!--列表模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <!--          <el-button icon="el-icon-download" type="primary" @click="download">
            导出
          </el-button>-->
          <el-table
            ref="tableSort"
            v-loading="listLoading"
            :data="list"
            :element-loading-text="elementLoadingText"
            style="margin-top: 15px"
            border
          >
            <el-table-column
              label="日志时间"
              sortable
              prop="createTime"
              align="center"
              width="160"
            >
              <template slot-scope="scope">
                {{ scope.row.createTime || "-" }}
              </template>
            </el-table-column>
            <el-table-column prop="terminalUnit" label="终端设备" width="150">
              <template slot-scope="scope">
                {{ scope.row.terminalUnit || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="操作人员" prop="createBy" width="130">
              <template slot-scope="scope">
                {{ scope.row.createBy || "-" }}
              </template>
            </el-table-column>
            <el-table-column label="IP地址" prop="ip" width="130">
              <template slot-scope="scope">
                {{ scope.row.ip || "-" }}
              </template>
            </el-table-column>

            <el-table-column label="内容" min-width="250" prop="operContent">
              <template slot-scope="scope">
                {{ scope.row.operContent || "-" }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>
      </el-col>
    </el-row>

    <!--分页模块-->
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="12" :lg="24" :xl="24">
        <el-card>
          <el-pagination
            :background="background"
            :current-page="queryForm.pageNum"
            :layout="layout"
            :page-size="queryForm.pageSize"
            :total="total"
            @current-change="handleCurrentChange"
            @size-change="handleSizeChange"
          ></el-pagination>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
  import { getLog } from "@/api/log.js";

  import { returnBitTimestamp, returnDeadtimestamp, getRoleKey } from "@/utils";
  import { mapGetters } from "vuex";

  export default {
    name: "Index",
    components: {},

    data() {
      return {
        list: [],

        listLoading: true,
        layout: "total, sizes, prev, pager, next, jumper",
        total: 0,
        background: true,

        elementLoadingText: "正在加载...",
        queryForm: {
          date: [], // 表单数据，不传递到后端
          dateEnd: "",
          dateStart: "",
          operContent: "",

          pageNum: 1,
          pageSize: 10,
          nextUserNo: "",
          nextUserRoleNo: "",
          userNo: "",
          userRoleNo: "",
        },
        // 查询用户操作日志列表参数
        param: {
          body: {
            dateEnd: "",
            dateStart: "",
            operContent: "",
            pageNum: 1,
            pageSize: 10,
          },
          header: {
            currentTime: "2020-09-09 12:12:12",
            requestId: "1",
            sourceType: "site",
          },
        },

        pickerOptions: {
          shortcuts: [
            {
              text: "最近一周",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                picker.$emit("pick", [start, end]);
              },
            },
            {
              text: "最近一个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                picker.$emit("pick", [start, end]);
              },
            },
            {
              text: "最近三个月",
              onClick(picker) {
                const end = new Date();
                const start = new Date();
                start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                picker.$emit("pick", [start, end]);
              },
            },
          ],
        },
      };
    },
    computed: {
      ...mapGetters({
        userNo: "user/userNo",
        roles: "user/roles",
      }),
    },
    created() {
      this.queryForm.userNo = this.userNo;
      this.queryForm.userRoleNo = getRoleKey("customer", this.roles);
      this.fetchData();
    },
    beforeDestroy() {},
    mounted() {},
    methods: {
      // 获取用户工作台待办列表分页信息
      async fetchData() {
        this.listLoading = true;
        // 下单日期范围
        if (null === this.queryForm.date) {
          this.queryForm.dateStart = "";
          this.queryForm.dateEnd = "";
        } else if (null !== this.queryForm.date) {
          if (0 < this.queryForm.date.length) {
            this.queryForm.dateStart = returnBitTimestamp(
              this.queryForm.date[0]
            );
            this.queryForm.dateEnd = returnDeadtimestamp(
              this.queryForm.date[1]
            );
          } else {
            this.queryForm.dateStart = "";
            this.queryForm.dateEnd = "";
          }
        }

        this.param.header.currentTime = returnBitTimestamp(new Date());
        this.param.body = Object.assign({}, this.queryForm);
        const { body } = await getLog(this.param);
        this.list = body.records;
        this.total = body.total;
        let refresh_data = {
          todo_total: this.total,
          isDone: false,
        };
        setTimeout(() => {
          this.listLoading = false;
        }, 500);
      },

      // 重置
      resetForm(formName) {
        if (this.$refs[formName] !== undefined) {
          this.$refs[formName].resetFields();
        }
      },

      handleQuery() {
        this.queryForm.pageNum = 1;
        this.fetchData();
      },
      handleSizeChange(val) {
        this.queryForm.pageSize = val;
        this.fetchData();
      },
      handleCurrentChange(val) {
        this.queryForm.pageNum = val;
        this.fetchData();
      },
      download() {},
    },
  };
</script>
<style scoped>
  .el-col {
    margin-bottom: 10px;
  }
  .el-col :last-child {
    margin-bottom: 0;
  }

  .el-date-editor--daterange.el-input,
  .el-date-editor--daterange.el-input__inner,
  .el-date-editor--timerange.el-input,
  .el-date-editor--timerange.el-input__inner {
    width: 100%;
  }

  .el-select,
  .el-cascader {
    width: 100%;
  }

  .el-dropdown-menu__item {
    padding: 0;
  }
  .el-table /deep/.warning-row {
    background: #f8b7bc;
  }
  .my-form-class {
    margin-bottom: -10px !important;
  }
</style>
<style>
  .my-back-img {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 4px;
    left: 2px;
  }
</style>
